var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"width_1920"},[_c('img',{staticClass:"image1",attrs:{"src":_vm.topPic}}),(_vm.projectList.length != 0)?_c('div',{staticClass:"name"},[_c('div',{staticClass:"w1790"}),(_vm.projectList[0])?_c('div',{class:_vm.projectList[0].title.length == 4
          ? 'w107'
          : _vm.projectList[0].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[0].id)}}},[_c('div',{class:_vm.projectList[0].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[0].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[1])?_c('div',{class:_vm.projectList[1].title.length == 4
          ? 'w107'
          : _vm.projectList[1].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[1].id)}}},[_c('div',{class:_vm.projectList[1].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[1].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[2])?_c('div',{class:_vm.projectList[2].title.length == 4
          ? 'w107'
          : _vm.projectList[2].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[2].id)}}},[_c('div',{class:_vm.projectList[2].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[2].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[3])?_c('div',{class:_vm.projectList[3].title.length == 4
          ? 'w107'
          : _vm.projectList[3].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[3].id)}}},[_c('div',{class:_vm.projectList[3].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[3].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[4])?_c('div',{class:_vm.projectList[4].title.length == 4
          ? 'w107'
          : _vm.projectList[4].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[4].id)}}},[_c('div',{class:_vm.projectList[4].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[4].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[5])?_c('div',{class:_vm.projectList[5].title.length == 4
          ? 'w107'
          : _vm.projectList[5].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[5].id)}}},[_c('div',{class:_vm.projectList[5].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[5].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[6])?_c('div',{class:_vm.projectList[6].title.length == 4
          ? 'w107'
          : _vm.projectList[6].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[6].id)}}},[_c('div',{class:_vm.projectList[6].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[6].title)+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[7])?_c('div',{class:_vm.projectList[7].title.length == 4
          ? 'w107'
          : _vm.projectList[7].title.length == 5
          ? 'w158'
          : 'w165',staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.clickPro(_vm.projectList[7].id)}}},[_c('div',{class:_vm.projectList[7].status != 1 ? 'normal_text' : 'current_text center'},[_vm._v(" "+_vm._s(_vm.projectList[7].title)+" ")])]):_vm._e(),_vm._m(0),_c('div',{staticClass:"w34"}),_c('div',{staticClass:"w60"})]):_vm._e(),_c('div',{staticClass:"row"},[_c('div',{staticClass:"column"},_vm._l((_vm.sportslist),function(item,index){return _c('div',{key:index},[_c('div',{class:index == 0 ? 'pos_b' : 'pos_b1'},[(index == 0)?_c('div',{staticClass:"black_bg"}):_c('div',{staticClass:"black_bg3"}),_c('div',{staticClass:"label"},[_c('div',{staticClass:"w534"},[(index == 0)?_c('div',{staticClass:"rank_num"},[_vm._v(_vm._s(item.rank))]):_c('div',{staticClass:"rank_num1"},[_vm._v(_vm._s(item.rank))]),_c('div',{staticClass:"row"},[_c('img',{staticClass:"image",attrs:{"src":item.logo}}),(index == 0)?_c('div',{staticClass:"column1"},[_c('div',{staticClass:"team_text"},[_vm._v(_vm._s(item.teamName))]),_c('div',{staticClass:"school_text"},[_vm._v(_vm._s(item.schoolName))])]):_c('div',{staticClass:"column1"},[_c('div',{staticClass:"team_text1"},[_vm._v(_vm._s(item.teamName))]),_c('div',{staticClass:"school_text1"},[_vm._v(_vm._s(item.schoolName))])])])]),_c('div',{staticClass:"color1"}),(_vm.projectList[0])?_c('div',{class:_vm.projectList[0].title.length == 4
                  ? 'w107'
                  : _vm.projectList[0].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[0]
                    ? _vm.projectList[0].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[0] ? item.scores[0] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[0] ? item.scores[0] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[1])?_c('div',{class:_vm.projectList[1].title.length == 4
                  ? 'w107'
                  : _vm.projectList[1].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[1]
                    ? _vm.projectList[1].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[1] ? item.scores[1] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[1] ? item.scores[1] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[2])?_c('div',{class:_vm.projectList[2].title.length == 4
                  ? 'w107'
                  : _vm.projectList[2].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[2]
                    ? _vm.projectList[2].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[2] ? item.scores[2] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[2] ? item.scores[2] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[3])?_c('div',{class:_vm.projectList[3].title.length == 4
                  ? 'w107'
                  : _vm.projectList[3].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[3]
                    ? _vm.projectList[3].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[3] ? item.scores[3] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[3] ? item.scores[3] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[4])?_c('div',{class:_vm.projectList[4].title.length == 4
                  ? 'w107'
                  : _vm.projectList[4].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[4]
                    ? _vm.projectList[4].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[4] ? item.scores[4] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[4] ? item.scores[4] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[5])?_c('div',{class:_vm.projectList[5].title.length == 4
                  ? 'w107'
                  : _vm.projectList[5].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[5]
                    ? _vm.projectList[5].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[5] ? item.scores[5] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[5] ? item.scores[5] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[6])?_c('div',{class:_vm.projectList[6].title.length == 4
                  ? 'w107'
                  : _vm.projectList[6].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[6]
                    ? _vm.projectList[6].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[6] ? item.scores[6] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[6] ? item.scores[6] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"w152"}),(_vm.projectList[7])?_c('div',{class:_vm.projectList[7].title.length == 4
                  ? 'w107'
                  : _vm.projectList[7].title.length == 5
                  ? 'w158'
                  : 'w165'},[(index == 0)?_c('div',{class:item.scores[7]
                    ? _vm.projectList[7].status != 1
                      ? 'score2 center'
                      : 'score1 center'
                    : 'score3 center'},[_vm._v(" "+_vm._s(item.scores[7] ? item.scores[7] : "--")+" ")]):_c('div',{staticClass:"score4 center"},[_vm._v(" "+_vm._s(item.scores[7] ? item.scores[7] : "--")+" ")])]):_vm._e(),_c('div',{staticClass:"row w133"},[_c('div',{staticClass:"flex"}),(index == 0)?_c('div',{staticClass:"total_score1"},[_vm._v(" "+_vm._s(item.scores[_vm.projectList.length])+" ")]):_c('div',{staticClass:"total_score2"},[_vm._v(" "+_vm._s(item.scores[_vm.projectList.length])+" ")])]),_c('div',{staticClass:"w34"})])])])}),0),_c('div',{staticClass:"w60"})]),_c('div',{staticClass:"h50"})])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row w133"},[_c('div',{staticClass:"flex"}),_c('div',{staticClass:"total_score"},[_vm._v("总 分")])])}]

export { render, staticRenderFns }